import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from "@chakra-ui/react";

import { axiosPost } from "../../utils/api";
import { API_ROUTES, ROOT_API } from "utils/constant";

const CreateChapterApi = ROOT_API + API_ROUTES.CREATE_CHAPTER;
const CreateChapter = ({ isOpen, onClose, refetch,idComic }) => {
  const cancelRef = React.useRef();
  const [name , setname] = useState("")
  const [priority , setPriority] = useState("")
  const toast = useToast();
  const clickCreateChapter = async () => {
    const chapterData = {
        comicId: idComic,
        name:name,
        priority:priority
    };
    try {
      const response = await axiosPost(CreateChapterApi, chapterData);
      if (response.data.code === 0) {
        toast({
          title: response.data.msg,
          status: "success",
          duration: 9000,
        });
        refetch();
        onClose();
      } else {
        toast({
          title: response.data.msg,
          status: "error",
          duration: 9000,
        });
      }
    } catch (error) {
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          "Create Chapter Fail",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered>
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Infomation Chapter</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Priority</FormLabel>
              <Input
                type="text"
                placeholder="Enter Priority"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
              />
            </FormControl>

          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              onClick={() => {
                clickCreateChapter();
              }}>
              Tạo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateChapter;