import {
  Td,
  Text,
  Tr,
  useColorModeValue,
  IconButton,
  FormControl,
  Switch,
  useDisclosure
} from "@chakra-ui/react";
import React from "react";
import { MdOutlineContentPasteSearch } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FaComments } from "react-icons/fa";
import { FaFileImage } from "react-icons/fa";
import UpLoadImgChapter from "./UpLoadImgChapter";
function ChapterComicRow(props) {
  const {isOpen , onOpen , onClose } = useDisclosure();
  const { slugComic,slugChapter,name, id, date,refetch, isLast, totalComment,totalLike } = props;
  const history = useHistory()
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const handleCommentsComic = () => {
    history.push(`/admin/commentschapter/${id}/commentschapter`);
  };
  const handleContentChapter = () => {
    history.push(`/admin/chaptercontent/${id}/chaptercontent`);
  };
  return (
    <>
      <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {name}
          </Text>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" pl="38px" color={textColor} fontWeight="bold">
            {totalComment}
          </Text>
        </Td>

        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" pl="30px" color={textColor} fontWeight="bold">
            {totalLike}
          </Text>
        </Td>

        <Td borderColor={borderColor}  borderBottom={isLast ? "none" : null}>
            <FormControl display='flex'  alignItems='center'>
                <Switch id='' isChecked={status} />
            </FormControl>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {date}
          </Text>
        </Td>

      <Td borderColor={borderColor} pl="80px" borderBottom={isLast ? "none" : null}>
        <IconButton
          p={2}
          bg="transparent"
          onClick={() => {
            handleCommentsComic();
          }}
        >
          <FaComments />
        </IconButton>
      </Td>
      <Td borderColor={borderColor} pl="60px" borderBottom={isLast ? "none" : null}>
        <IconButton
          p={2}
          bg="transparent"
          onClick={handleContentChapter}
        >
          <MdOutlineContentPasteSearch />
        </IconButton>
      </Td>
      <Td borderColor={borderColor} pl="60px" borderBottom={isLast ? "none" : null}>
        <IconButton
          p={2}
          bg="transparent"
          onClick={onOpen}
        >
          <FaFileImage />
        </IconButton>
      </Td>
      </Tr>
      {isOpen && <UpLoadImgChapter
        id={id}
        slugComic={slugComic}
        slugChapter={slugChapter}
        refetch={refetch}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />}
    </>
  );
}

export default ChapterComicRow;
