import React, { useState } from "react";
import {
  Button,
  Input,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Avatar,
  VStack 
} from "@chakra-ui/react";
import { axiosPost } from "../../utils/api";
import { API_ROUTES, ROOT_API } from "utils/constant";
const UpdateOrder = ({ isOpen, onClose, refetch,idChapter,dataContent }) => {
  const UpdateOrderApi = ROOT_API + API_ROUTES.UPDATE_ORDER_CONTENT;
  const cancelRef = React.useRef();
  const toast = useToast();
  const [orderValues, setOrderValues] = useState(dataContent ? dataContent.map((preview) => preview.order) : []);
  const [selectedId, setSelectedId] = useState(dataContent ? dataContent.map((preview) => preview._id) : []);
  const chapterContents = {
    "chapterContents": Array.from({ length: selectedId.length }, (_, index) => ({
      "chapterContentID": selectedId[index],
      "order": orderValues[index]
    }))
  };
  const clickUpdateOrder = async () => {
    try {
      const response = await axiosPost(`${UpdateOrderApi}/${idChapter}`, chapterContents);
      if (response.data.code === 0) {
        toast({
          title: response.data.msg,
          status: "success",
          duration: 9000,
        });
        refetch();
        onClose();
      } else {
        toast({
          title: response.data.msg,
          status: "error",
          duration: 9000,
        });
      }
    } catch (error) {
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          "Update Order Chapter Fail",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    };
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Update Order</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody maxH="600px" overflow="auto">
            <VStack align="start" spacing="4">
              {dataContent?.map((preview, index) => (
                <FormControl display="flex" columnGap="30px" key={index}>
                  <Avatar
                    name={`IMG-${index}`}
                    src={`${ROOT_API}/${preview.path}`}
                    w="100px"
                    h="100px"
                    borderRadius="12px"
                    mb="2"
                    value={selectedId[index]}
                    onChange={(e) => {
                        const updatedIdSeclect = [...selectedId];
                        updatedIdSeclect[index] = e.target.value;
                        setSelectedId(updatedIdSeclect);
                      }}
                  />
                  <FormControl>
                  <FormLabel>Order</FormLabel>
                  <Input
                    key={index}
                    type="number"
                    placeholder="Enter Order"
                    value={orderValues[index]} 
                    onChange={(e) => {
                      const updatedOrderValues = [...orderValues];
                      updatedOrderValues[index] = e.target.value;
                      setOrderValues(updatedOrderValues);
                    }}
                  />
                  </FormControl>
                </FormControl>
              ))}
            </VStack>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              onClick={() => {
                clickUpdateOrder();
              }}
            >
              Update
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
export default UpdateOrder;