import {
    Flex,
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    useDisclosure
  } from "@chakra-ui/react";
  import useAxios from "axios-hooks";
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody.js";
  import CardHeader from "components/Card/CardHeader.js";
  import React, { useState } from "react";
  import Loading from "components/Layout/Loading";
  import { TablePagination } from "@trendmicro/react-paginations";
  import { initialFilter } from "utils/constant";
  import { API_ROUTES , ROOT_API } from "utils/constant";
  import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
  import ChapterContentRow from "components/Comic/ChapterContentRow";
  import UpdateOrder from "components/Comic/UpdateOrder";

  function ChapterContent() {
    const { isOpen, onOpen, onClose  } = useDisclosure();
    const chapterContentApi = ROOT_API + API_ROUTES.CHAPTER_CONTENT
    const textColor = useColorModeValue("gray.700", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const location = useLocation();
    const spliceChapterUrl = location.pathname.match(/\/chaptercontent\/([^/]+)\//);
    const idChapter = spliceChapterUrl && spliceChapterUrl[1];
    const [filter, setFilter] = useState(initialFilter);
    const [{ data, loading }, refetch] = useAxios({
        url: `${chapterContentApi}/${idChapter}`,
        params:{...filter}
    });
    return (
      <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
        <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Chapter
            </Text>
          <Button 
            variant="primary"
            maxH="30px"
            m="10px"
            onClick={onOpen}
          >
            Update Order
          </Button>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Loading />
            ) : (
              <>
                <Table variant="simple" color={textColor}>
                  <Thead>
                    <Tr my=".8rem" pl="0px" color="gray.400">
                      <Th pl="24px" borderColor={borderColor} color="gray.400">
                        ID
                      </Th>
                      <Th pl="46px" borderColor={borderColor} color="gray.400">
                      Order
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data?.data?.map((row) => {
                      return (
                        <ChapterContentRow
                          dataContent = {data?.data}
                          id={row?._id}
                          order={row.order}
                        />
                      );
                    })}
                  </Tbody>
                </Table>
                <Flex justifyContent="flex-end">
                  <TablePagination
                    type="full"
                    page={data?.pagination?.page}
                    pageLength={data?.pagination?.pageSize}
                    totalRecords={data?.pagination?.count}
                    onPageChange={({ page, pageLength }) => {
                      setFilter({
                        ...filter,
                        pageSize: pageLength,
                        pageIndex: page - 1
                      })
                    }}
                    prevPageRenderer={() => <i className="fa fa-angle-left" />}
                    nextPageRenderer={() => <i className="fa fa-angle-right" />}
                  />
                </Flex>
                {isOpen && <UpdateOrder
                idChapter={idChapter}
                  dataContent={data?.data}
                  refetch={refetch}
                  isOpen={isOpen}
                  onOpen={onOpen}
                  onClose={onClose}
                />}
              </>
            )}
          </CardBody>
        </Card>
      </Flex>
    );
  }
  
  export default ChapterContent;
  