import React, { useState } from "react";
import {
  Button,
  Input,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Avatar,
} from "@chakra-ui/react";
import { axiosPost } from "../../utils/api";
import { API_ROUTES, ROOT_API } from "utils/constant";
const UpLoadImgChapter = ({ isOpen, onClose, refetch, slugChapter, id, slugComic }) => {
  const uploadImg = ROOT_API + API_ROUTES.UPLOAD_IMG;
  const fileInput = React.useRef(null);
  const [fileImages, setFileImages] = useState([]);
  const [imgPreviews, setImgPreviews] = useState([]);
  const cancelRef = React.useRef();
  const toast = useToast();

  const processImages = (files) => {
    const images = [];
    const previews = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const image = URL.createObjectURL(file);
      images.push(file);
      previews.push(image);
    }
    setFileImages(images);
    setImgPreviews(previews);
  };
  const clickUploadImg = async () => {
    const data = new FormData();
    data.append('chapterID', id);

    for (let i = 0; i < fileImages.length; i++) {
      data.append(`image`, fileImages[i]);
    }
    const path = `uploads/manga/${slugComic}/${slugChapter}`;
    const headers = {
      "content-type": "multipart/form-data",
    };
    try {
      const response = await axiosPost(`${uploadImg}?path=${path}`, data, headers);
      if (response.data.message === "Ok") {
        toast({
          title: "Upload Image Chapter Success",
          status: "success",
          duration: 9000,
        });
        refetch();
        onClose();
      } else {
        toast({
          title: response.data.message,
          status: "error",
          duration: 9000,
        });
      }
    } catch (error) {
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          "Upload Img Fail",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Upload Img</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <FormControl>
              <FormLabel>Image Files</FormLabel>
              {imgPreviews.map((preview, index) => (
                <Avatar
                  key={index}
                  name={`IMG-${index}`}
                  src={preview}
                  w="100px"
                  h="100px"
                  borderRadius="12px"
                  me="18px"
                />
              ))}
              <Input
                border="none"
                pl="3px"
                type="file"
                ref={fileInput}
                multiple
                onChange={(event) => processImages(event.target.files)}
              />
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" ml={3} onClick={clickUploadImg}>
              Upload
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UpLoadImgChapter;
