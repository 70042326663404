import {
  Td,
  Text,
  Tr,
  useColorModeValue,
  IconButton,
  useToast,
  FormControl,
  Switch,
} from "@chakra-ui/react";
import React from "react";
import { axiosPost } from "utils/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { API_ROUTES, ROOT_API } from "utils/constant";
import { GrChapterAdd, GrEdit } from "react-icons/gr";
import { FaComments } from "react-icons/fa";
function ComicRow(props) {
  const {
    categories,
    slug,
    name,
    isDone,
    id,
    ishot,
    totalLike,
    date,
    isLast,
    totalComment,
    refetch,
    comicRow,
    handleEditComic
  } = props;
  const history = useHistory();
  const textColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const toast = useToast();
  const handleAllChapter = () => {
    history.push({
      pathname: `/admin/chapter/${id}/chapter/${slug}/slug`,
    });
  };
  const handleCommentsComic = () => {
    history.push(`/admin/commentscomic/${id}/commentscomic`);
  };
  const comicHot = async () => {
    const apiHot = ROOT_API + API_ROUTES.COMIC_IS_HOT;
    const data = {
      id: id,
    };
    try {
      const response = await axiosPost(apiHot, data);
      if (response.data.code === 0) {
        toast({
          title: response.data.msg,
          duration: 9000,
        });
        refetch();
      }
    } catch (error) {
      console.log(error);
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          "Update Hot Fail",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  return (
    <>
      <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" w="300px" color={textColor} fontWeight="bold">
            {name}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <Text fontSize="md" color={textColor} fontWeight="bold">
            {categories}
          </Text>
        </Td>
        <Td
          borderColor={borderColor}
          textAlign="center"
          borderBottom={isLast ? "none" : null}
        >
          <Text
            fontSize="md"
            w="100px"
            textAlign="center"
            color={textColor}
            fontWeight="bold"
          >
            {totalComment}
          </Text>
        </Td>
        <Td
          borderColor={borderColor}
          textAlign="center"
          borderBottom={isLast ? "none" : null}
        >
          <Text
            fontSize="md"
            w="100px"
            textAlign="center"
            color={textColor}
            fontWeight="bold"
          >
            {totalLike}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <FormControl
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Switch id="" isChecked={isDone} />
          </FormControl>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <FormControl
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Switch id="" isChecked={ishot} onChange={comicHot} />
          </FormControl>
        </Td>
        <Td
          borderColor={borderColor}
          pl="0"
          pr="0"
          borderBottom={isLast ? "none" : null}
        >
          <Text fontSize="md" color={textColor} w="82px" fontWeight="bold">
            {date}
          </Text>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handleCommentsComic();
            }}
          >
            <FaComments />
          </IconButton>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handleAllChapter();
            }}
          >
            <GrChapterAdd />
          </IconButton>
        </Td>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
          <IconButton
            p={2}
            bg="transparent"
            onClick={() => {
              handleEditComic(comicRow);
            }}
          >
            <GrEdit />
          </IconButton>
        </Td>
      </Tr>
    </>
  );
}

export default ComicRow;
