import {
    Td,
    Text,
    Tr,
    useColorModeValue
  } from "@chakra-ui/react";
  import React from "react";
  function ChapterContentRow(props) {
    const {id ,order ,isLast  } = props;
    const textColor = useColorModeValue("gray.500", "white");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    return (
      <>
        <Tr>
        <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Text fontSize="md" color={textColor} fontWeight="bold">
              {id}
            </Text>
          </Td>
          <Td borderColor={borderColor} borderBottom={isLast ? "none" : null}>
            <Text fontSize="md" pl="38px" color={textColor} fontWeight="bold">
              {order}
            </Text>
          </Td>
        </Tr>
      </>
    );
  }
  
  export default ChapterContentRow;
  