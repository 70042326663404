import React, { useEffect, useState } from "react";
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Avatar,
  FormControl,
  FormLabel,
  Input,
  Flex,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import InputController from "components/Form/InputController";
import SelectController from "components/Form/SelectController";
import { mappingOptionSelect } from "utils/mapping";
import { ROOT_API, API_ROUTES } from "utils/constant";
import { axiosPost } from "utils/api";

const schema = yup.object().shape({
  name: yup.string().nullable(),
  avatar: yup.string().nullable(),
  thumbnail: yup.string().nullable(),
  categories: yup
    .array()
    .of(
      yup.object().shape({
        value: yup.string(),
        label: yup.string(),
      })
    )
    .nullable(),
  tags: yup.string().nullable(),
  author: yup.string().nullable(),
  description: yup.string().nullable(),
});

const UpdateComic = ({
  isOpen,
  editComic,
  onClose,
  categoryFilter,
  refetch,
}) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const fileInput = React.useRef(null);
  const fileInputAvatar = React.useRef(null);
  const [imgPreview, setImgPreview] = useState();
  const [imgPreviewAvatar, setImgPreviewAvatar] = useState();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      avatar: "",
      thumbnail: "",
      categories: {},
      tags: "",
      author: "",
      description: "",
    },
  });

  const onSubmit = async (values) => {
    const formatCategories = values?.categories?.map((item) => item?.value);
    try {
      const response = await axiosPost(ROOT_API + API_ROUTES.UPDATE_COMIC, {
        ...values,
        id: editComic?._id,
        categories: formatCategories,
      });
      if (response?.data?.code === 0) {
        toast({
          title: "Update Comic Successfully",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
        refetch();
      }
    } catch (error) {
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          "Update Comic Fail",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
    onClose();
  };

  const onChangeImgAvatar = () => {
    const btn = fileInputAvatar?.current;
    if (btn !== null) {
      btn.click();
    }
  };

  const onChangeImg = () => {
    const btn = fileInput?.current;
    if (btn !== null) {
      btn.click();
    }
  };

  useEffect(() => {
    setImgPreviewAvatar(ROOT_API + editComic?.avatar);
    setImgPreview(ROOT_API + "/" + editComic?.thumbnail);
    reset({
      name: editComic?.name,
      avatar: editComic?.avatar,
      thumbnail: editComic?.thumbnail,
      categories: editComic?.categories
        ? mappingOptionSelect(editComic?.categories)
        : null,
      tags: editComic?.tags,
      author: editComic?.author,
      description: editComic?.description,
    });
  }, [editComic]);

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">
            UPDATE COMIC
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <Flex>
                <FormControl>
                  <FormLabel>Avatar</FormLabel>
                  {imgPreviewAvatar && (
                    <Avatar
                      name="IMG"
                      src={imgPreviewAvatar}
                      w="100px"
                      h="100px"
                      borderRadius="12px"
                      me="18px"
                      mb={3}
                      objectFit={"contain"}
                      onClick={onChangeImgAvatar}
                    />
                  )}
                  <Input
                    fontSize={12}
                    name="avatar"
                    control={control}
                    border="none"
                    pl="3px"
                    type="file"
                    ref={fileInputAvatar}
                    onChange={(event) => {
                      //   setFileImgAvatar(event.target.files[0]);
                      const image = window.URL.createObjectURL(
                        event.target.files[0]
                      );
                      setImgPreviewAvatar(image);
                    }}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Thumbnail</FormLabel>
                  {imgPreview && (
                    <Avatar
                      name="IMG"
                      src={imgPreview}
                      w="100px"
                      h="100px"
                      borderRadius="12px"
                      me="18px"
                      mb={3}
                      objectFit={"contain"}
                      onClick={onChangeImg}
                    />
                  )}
                  <Input
                    fontSize={12}
                    name="thumbnail"
                    control={control}
                    border="none"
                    pl="3px"
                    type="file"
                    ref={fileInput}
                    onChange={(event) => {
                      //   setFileImg(event.target.files[0]);
                      const image = window.URL.createObjectURL(
                        event.target.files[0]
                      );
                      setImgPreview(image);
                    }}
                  />
                </FormControl>
              </Flex>
              <InputController
                styleContainer={{ pt: "4" }}
                control={control}
                name="name"
                label="Title"
                placeholder="Enter Title"
              />
              <SelectController
               styleContainer={{ pt: "4" }}
                isMulti
                control={control}
                name="categories"
                label="Categories"
                placeholder="Enter Categories"
                options={mappingOptionSelect(categoryFilter)}
              />
              <InputController
                styleContainer={{ pt: "4" }}
                control={control}
                name="tags"
                label="Tags"
                placeholder="Enter Tags"
              />
              <InputController
                styleContainer={{ pt: "4" }}
                control={control}
                name="author"
                label="Author"
                placeholder="Enter Author"
              />
              <InputController
                type="area"
                styleContainer={{ pt: "4" }}
                control={control}
                name="description"
                label="Description"
                placeholder="Enter Description"
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="red" ml={3} onClick={handleSubmit(onSubmit)}>
              Save
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default UpdateComic;
