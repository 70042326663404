import React, { useState } from "react";
import {
  Button,
  Input,
  FormLabel,
  FormControl,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
  Avatar,
} from "@chakra-ui/react";
import { axiosPost } from "../../utils/api";
import { API_ROUTES, ROOT_API } from "utils/constant";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

const CreateCommic = ROOT_API + API_ROUTES.CREATE_COMIC;
const CreateComic = ({ isOpen, onClose, refetch ,categoryFilter }) => {
  const cancelRef = React.useRef();
  const fileInput = React.useRef(null);
  const [name , setname] = useState("")
  const [categories , setCategories] = useState()
  const [tags , setTags] = useState("")
  const [author , setAuthor] = useState("")
  const [description , setDescription] = useState("")
  const [fileImg, setFileImg] = useState();
  const [imgPreview, setImgPreview] = useState();
  const toast = useToast();
  const onChangeImg = () => {
    const btn = fileInput?.current;
    if (btn !== null) {
      btn.click();
    }
  };
  const colourOptions = categoryFilter?.map((cate) => ({
    value: cate.slug,
    label: cate.name,
  })) || [];
  const clickCreateComic = async () => {
    const data = new FormData();
    data.append('name', name);
    data.append('thumbnail', fileImg);
    categories?.forEach((category, index) => {
      data.append(`categories[${index}]`, category);
    });
    data.append('tags', tags);
    data.append('author', author);
    data.append('description', description);
    const headers = {
        "content-type": "multipart/form-data",
      };
    try {
      const response = await axiosPost(CreateCommic,data,headers);
      if (response.data.code === 0) {
        toast({
          title: response.data.msg,
          status: "success",
          duration: 9000,
        });
        refetch();
        onClose();
      } else {
        toast({
          title: response.data.msg,
          status: "error",
          duration: 9000,
        });
      }
    } catch (error) {
      toast({
        title:
          error?.response?.data?.errors?.errors[0]?.msg ||
          error?.response?.data?.msg ||
          "Create Comic Fail",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };
  
  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered>
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Infomation Comic</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
          <FormControl>
              <FormLabel>
                Thumbnail
              </FormLabel>
              {imgPreview && (
                <Avatar
                  name="IMG"
                  src={imgPreview}
                  w="100px"
                  h="100px"
                  borderRadius="12px"
                  me="18px"
                  mb={3}
                  onClick={onChangeImg}
                />
              )}
              <Input
                border = "none"
                pl="3px"
                type="file"
                ref={fileInput}
                onChange={(event) => {
                  setFileImg(event.target.files[0]);
                  const image = URL.createObjectURL(event.target.files[0]);
                  setImgPreview(image);
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                placeholder="Enter Name"
                value={name}
                onChange={(e) => setname(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Categories</FormLabel>
              <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={colourOptions}
                onChange={(selectedOptions) => {
                  const selectedValues = selectedOptions?.map((option) => option.value);
                  setCategories(selectedValues);
                }}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderColor: '#ccc',
                  }),
                }}
              >
          </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Tags</FormLabel>
              <Input
                type="text"
                placeholder="Enter Tags"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Author</FormLabel>
              <Input
                type="text"
                placeholder="Enter Author"
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Description</FormLabel>
              <Input
                type="text"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </FormControl>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              ml={3}
              onClick={() => {
                clickCreateComic();
              }}>
              Tạo
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateComic;
