import {
  Button,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Input,
  Select,
  FormLabel,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import useAxios from "axios-hooks";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import ComicRow from "components/Comic/ComicRow";
import React, { useState, useEffect } from "react";
import Loading from "components/Layout/Loading";
import { checkLogin } from "../../../utils/authentication";
import { TablePagination } from "@trendmicro/react-paginations";
import { initialFilter } from "utils/constant";
import { API_ROUTES, ROOT_API } from "utils/constant";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import CreateComic from "components/Comic/CreateComic";
import UpdateComic from "components/Comic/EditComic";

function Comic() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenUpdate,
    onOpen: onOpenUpdate,
    onClose: onCloseUpdate,
  } = useDisclosure();
  const history = useHistory();
  const comicApi = ROOT_API + API_ROUTES.COMIC_API;
  const categoryApi = ROOT_API + API_ROUTES.CATEGORY_API;
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const [comic, setComic] = useState([]);
  const [filter, setFilter] = useState(initialFilter);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [categorySlug, setCategorySlug] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [comicEdit, setComicEdit] = useState(null);
  const isLoggedIn = checkLogin();
  const [{ data, loading, error }, refetch] = useAxios({
    url: comicApi,
    params: filter,
  });

  const handleSearchKeywordChange = (event) => {
    setSearchKeyword(event.target.value);
  };
  const handleCategorySlug = (event) => {
    setCategorySlug(event.target.value);
  };
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleFilterClick = () => {
    setFilter({ categorySlug, searchKeyword, ...initialFilter });
  };

  const clearFilter = () => {
    setFilter(initialFilter);
    setSearchKeyword("");
    setCategorySlug("");
    setSelectedStatus("");
  };

  const dataCategoryFilter = async () => {
    try {
      const response = await axios.get(categoryApi);
      setCategoryFilter(response.data.data);
    } catch (err) {
      console.error("Error:", err);
    }
  };

  const handleEditComic = (comic) => {
    setComicEdit(comic);
    onOpenUpdate();
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return history.push("/auth/signin");
    }
    setComic(data?.data);
  }, [isLoggedIn, data]);

  useEffect(() => {
    dataCategoryFilter();
  }, [refetch]);
  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px" }}>
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb="0px">
        <CardHeader p="6px 0px 22px 0px">
          <Text fontSize="xl" color={textColor} fontWeight="bold">
            Comic
          </Text>
          <Button variant="primary" maxH="30px" m="10px" onClick={onOpen}>
            Add
          </Button>
        </CardHeader>
        <Flex flexDirection={"column"} flexWrap="wrap" gap={4} mb={8}>
          <Flex
            w={{
              base: "100%",
              md: "80%",
            }}
            flexDirection={{
              base: "column",
              md: "row",
            }}
            gap={4}
          >
            <Flex
              w={{
                base: "100%",
                md: "50%",
              }}
            >
              <FormLabel minWidth="80px" m="0" lineHeight={"40px"} htmlFor="IP">
                Tìm kiếm
              </FormLabel>
              <Input
                placeholder="Tìm theo tên truyện"
                w="100%"
                value={searchKeyword}
                onChange={handleSearchKeywordChange}
              />
            </Flex>
            <Flex
              w={{
                base: "100%",
                md: "50%",
              }}
            >
              <FormLabel lineHeight={"40px"} m="0" htmlFor="IP" minWidth="80px">
                Thể loại
              </FormLabel>
              <Select
                placeholder="Chọn thể loại"
                width={"100%"}
                maxH="30px"
                value={categorySlug}
                onChange={handleCategorySlug}
              >
                {categoryFilter?.map((cate, index) => (
                  <option key={index} value={cate.slug}>
                    {cate.name}
                  </option>
                ))}
              </Select>
            </Flex>
          </Flex>
          <Flex
            w={{
              base: "100%",
              md: "80%",
            }}
            flexDirection={{
              base: "column",
              md: "row",
            }}
            alignItems={"end"}
            gap={4}
          >
            <Flex
              w={{
                base: "100%",
                md: "50%",
              }}
            >
              <FormLabel lineHeight={"40px"} htmlFor="IP" minWidth="80px" m="0">
                Trạng thái
              </FormLabel>
              <Select
                placeholder="Chọn trạng thái"
                maxH="30px"
                m="0px"
                value={selectedStatus}
                onChange={handleStatusChange}
              >
                <option value="true">Hoàn Thành</option>
                <option value="false">Đang Cập Nhật</option>
              </Select>
            </Flex>
            <Flex
              w={{
                base: "100%",
                md: "50%",
              }}
              pl={"80px"}
            >
              <Button w="100px" onClick={handleFilterClick}>
                Filter
              </Button>
              <Button marginLeft="10px" w="100px" onClick={clearFilter}>
                Clear Filter
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <CardBody w="100%" overflowX="auto">
          {loading ? (
            <Loading />
          ) : (
            <>
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th pl="24px" borderColor={borderColor} color="gray.400">
                      Name
                    </Th>
                    <Th
                      pl="0"
                      textAlign="center"
                      borderColor={borderColor}
                      color="gray.400"
                    >
                      Categories
                    </Th>
                    <Th
                      borderColor={borderColor}
                      pl="24px"
                      pr="0"
                      color="gray.400"
                    >
                      Total Comment
                    </Th>
                    <Th
                      borderColor={borderColor}
                      pl="40px"
                      pr="0"
                      color="gray.400"
                    >
                      Total Like
                    </Th>
                    <Th
                      pl="24px"
                      textAlign="center"
                      borderColor={borderColor}
                      color="gray.400"
                    >
                      Status
                    </Th>
                    <Th
                      pl="24px"
                      textAlign="center"
                      borderColor={borderColor}
                      color="gray.400"
                    >
                      Hot
                    </Th>
                    <Th pl="24px" borderColor={borderColor} color="gray.400">
                      Date
                    </Th>
                    <Th pl="24px" borderColor={borderColor} color="gray.400">
                      Comments
                    </Th>
                    <Th pl="24px" borderColor={borderColor} color="gray.400">
                      View Chapter
                    </Th>
                    <Th pl="24px" borderColor={borderColor} color="gray.400">
                      Action
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {comic?.map((row, index, arr) => {
                    return (
                      <ComicRow
                        key={row._id}
                        isOpen={isOpen}
                        id={row._id}
                        comic={comic}
                        categories={row.categories
                          .map((category) => category.name)
                          .join(", ")}
                        isApproved={row.isApproved}
                        totalComment={
                          row.totalComment === ""
                            ? "no totalComment"
                            : row.totalComment
                        }
                        totalLike={row.totalLike}
                        date={moment(row.createdAt).format("DD-MM-YYYY")}
                        updatedAt={moment(row.updatedAt).format("DD-MM-YYYY")}
                        name={row.name}
                        slug={row.slug}
                        ishot={row.isHot}
                        status={row.status}
                        isDone={row.isDone}
                        refetch={refetch}
                        comicRow={row}
                        isLast={index === arr.length - 1 ? true : false}
                        handleEditComic={handleEditComic}
                      />
                    );
                  })}
                </Tbody>
              </Table>
              <Flex justifyContent="flex-end">
                <TablePagination
                  type="full"
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={({ page, pageLength }) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                  prevPageRenderer={() => <i className="fa fa-angle-left" />}
                  nextPageRenderer={() => <i className="fa fa-angle-right" />}
                />
              </Flex>
              {isOpen && (
                <CreateComic
                  categoryFilter={categoryFilter}
                  refetch={refetch}
                  isOpen={isOpen}
                  onOpen={onOpen}
                  onClose={onClose}
                />
              )}
              {isOpenUpdate && (
                <UpdateComic
                  categoryFilter={categoryFilter}
                  editComic={comicEdit}
                  refetch={refetch}
                  isOpen={isOpenUpdate}
                  onOpen={onOpenUpdate}
                  onClose={onCloseUpdate}
                />
              )}
            </>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Comic;
